import React from 'react';
import './App.css';
import { Stack, Text, Separator,  } from '@fluentui/react';
import { Button, Tooltip } from '@fluentui/react-components';
import { OpenRegular } from "@fluentui/react-icons";

// React component documentation:
// https://developer.microsoft.com/en-us/fluentui#/controls/web

const App: React.FC = () => {
  return (
    <main>
      
      <section>
        <header>
          <img src="oi.png" alt="Outlook Insight Logo" className='headerLogo'/>
          <div className='headerText'> 
            <div className='oiTextL'>Outlook Insight's</div>
            <div className='oiTextXL'>Knowledge Interpreter</div>
          </div>
        </header>

        <div className="main_content">
        <Stack>
          <Text variant="xxLarge">We've Moved...</Text>
        </Stack>
        <div className="main_body">
          <Stack tokens={{ childrenGap: 20, padding: 10 }}>
            <Stack>
              <Text variant="large">Please select an option:</Text>
            </Stack>

            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 40 }}>
                <a href='https://wadvr.knowledgeinterpreter.com' target='_blank' rel="noreferrer" aria-label='Enter the WA DVR Knowledge Interpreter environment.'>
                  <button tabIndex={-1} className="mainButton">WA DVR</button>
                </a>
                <a href='https://demo.ki.chat' target='_blank' rel="noreferrer" aria-label='Enter the demo Knowledge Interpreter environment.'>
                  <button tabIndex={-1} className="mainButton">DEMO</button>
                </a>
            </Stack>
          </Stack>
        </div>
        </div>

        <footer>
          <div className="footerNote">
            <b>NOTE:</b> Make sure to update your browser bookmarks to reflect the updated website links.
          </div>
        </footer>

      </section>
    </main>
  );
};

export default App;
